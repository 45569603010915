import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, withPrefix } from "gatsby"
import Header from "../components/header"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Header/>
    <header id="mainheader" className="height100vh photobg" style={{backgroundImage: `url(${withPrefix('images/404.jpg')})`}}>
      <div className="header-inner height100vh photobg-inner page404-inner">
        <div className="display-table">
          <div className="display-table-cell">
            <div className="row wow fadeInUp" data-wow-duration="1.5s" style={{visibility: 'hidden'}}>
              <div
                className="large-6 large-centered medium-8 medium-centered small-10 small-centered columns text-center">
                <h1
                  className="white border-bottom border-center">Strona, której szukasz nie istnieje</h1>
                <div className="font-mobile">
                  <Link to={'/'}
                     className="white">
                    Wróć do strony głównej
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </Layout>
)

export default NotFoundPage
